@custom-selector :--custom-btns .arxiv, .inspire, .slides, .journal;

.btn {
  color: currentcolor;
  background-color: gray(85%);
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  border: 2px solid gray(100%, 0);
  border-width: 2px 2px 0;   
  box-shadow: var(--btn-shadow) gray(55%);
  transition: opacity 200ms, background-color 200ms;
  &:hover, &:focus {
    opacity: 0.8;
    color: currentcolor;   
  }
  &:active, &:active:focus {
    box-shadow: var(--btn-press-shadow) gray(45%);
    transform: translateY(2px);
  }
  &:focus {
    box-shadow: var(--btn-shadow) gray(55%);
    border-color: gray(75%);
  }
  &.card-title {
    padding: 1rem;
    margin-bottom: 1.25rem;
    white-space: normal;
  }
  &.square {
    width: 4rem;
    height: 3.5rem;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-family: var(--font-family-sans-serif);
    & > * {
      width: 100%;
    }
  }

  &.frosted {
    background-color: color(white a(90%));
    box-shadow: var(--btn-shadow) color(white a(50%));
    &:hover, &:focus {
      background-color: white;
      opacity: 1;
    }
    &:active, &:active:focus {
      box-shadow: var(--btn-press-shadow) color(white a(40%));
    }
    &:focus {
      box-shadow: var(--btn-shadow) color(white a(50%));
      border-color: var(--blue1)80;
    }
  }
  /* 
  There are 4 colour variants
  - the common arxiv and inspire preprint repos (red and light blue respectively)
  - slides (from talks) (orange)
  - journals (any link to a journal where the paper was published) (teal)
  */
  &.arxiv {
    background-color: var(--arxiv-red);
    box-shadow: var(--btn-shadow) color(var(--arxiv-red) l(-15%));
    &:active, &:active:focus {
      box-shadow: var(--btn-press-shadow) color(var(--arxiv-red) l(-20%));
    }
    &:focus {
      box-shadow: var(--btn-shadow) color(var(--arxiv-red) l(-15%));
      border-color: color(var(--arxiv-red) l(-15%));
    }
  }
  &.inspire {
    background-color: var(--inspire-blue);
    box-shadow: var(--btn-shadow) color(var(--inspire-blue) l(-15%));
    &:active, &:active:focus {
      box-shadow: var(--btn-press-shadow) color(var(--inspire-blue) l(-20%));
    }
    &:focus {
      box-shadow: var(--btn-shadow) color(var(--inspire-blue) l(-15%));
      border-color: color(var(--inspire-blue) l(-15%));
    }
  }
  &.slides {
    background-color: var(--slides-orange);
    box-shadow: var(--btn-shadow) color(var(--slides-orange) l(-15%));
    &:active, &:active:focus {
      box-shadow: var(--btn-press-shadow) color(var(--slides-orange) l(-20%));
    }
    &:focus {
      box-shadow: var(--btn-shadow) color(var(--slides-orange) l(-15%));
      border-color: color(var(--slides-orange) l(-15%));
    }
  }
  &.journal {
    background-color: var(--journal-blue);
    box-shadow: var(--btn-shadow) color(var(--journal-blue) l(-15%));
    &:active, &:active:focus {
      box-shadow: var(--btn-press-shadow) color(var(--journal-blue) l(-20%));
    }
    &:focus {
      box-shadow: var(--btn-shadow) color(var(--journal-blue) l(-15%));
      border-color: color(var(--journal-blue) l(-15%));
    }
  }
}
:--custom-btns, :--custom-btns:hover, :--custom-btns:focus {
  color: white;
}
.btn-symbol:not(:last-child) {
  padding-top: 0.375rem;
  line-height: 1.25rem;
}
.btn-label {
  font-size: 0.625rem;
  padding-bottom: 0.25em;
  font-family: var(--font-family-base);
}


