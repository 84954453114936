@custom-selector :--heading h1, h2, h3, h4, h5, h6;
@custom-selector :--interact :hover, :active:focus, :focus;

html,
body {
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-family: var(--font-family-base);
  @media (--sm-viewport) {
    font-size: 16px;
  }
}

p {
  max-width: 48rem;
}

nav, header, footer, .btn, :--heading {
  font-family: var(--font-family-display);
}

:--heading {
  margin-bottom: 0.75rem;
}

header { 
  padding: 7rem 1rem 4rem;
  text-align: center; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .header-title {
    letter-spacing: 5vw;
    margin-right: -5vw;
    text-transform: uppercase;
    white-space: nowrap;
  }
  & .header-subtitle {
    margin: 0.5rem 0;
  }
  & hr {
      max-width: 1.5rem;
      border-color: white;
    }
  & .linknav {
    margin-top: 1rem;
    margin-bottom: 0;
  }
  & :--heading, & p {
    color: white;
    text-shadow: 0 0 4px gray(0%, 0.25);
  }
  @media (--md-viewport) {
    & .header-title {
      letter-spacing: 1em;
      margin-right: -1em;
    }
  }
}

section {
  padding: 4rem 0;
  & section {
    padding-top: 2rem;
  }
}

article { 
  &:hover i {
    transform: scale(1.05);
    filter: saturate(180%) brightness(85%);
  }
}

article, .section-header {
  & i {
    transition: all 200ms;
    padding: 0.5rem 0 1.5rem;
  }
}

.navbar {
  font-size: 0.875rem;
  transition: background-color 240ms ease-out;
  padding: 1.25rem 1rem;
  &.scrolled {
    padding: 0.5rem 1rem;
  }
  & .navbar-collapse {
    justify-content: flex-end;

  }
  & .container {
    margin: 0;
  }
  @media (--sm-viewport) {
    & .container {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.section-header {
  display: flex;
  justify-content: center;
  align-items: center;  
  flex-direction: column;
  text-align: center; 
  & :--heading {
    text-transform: uppercase;
  } 
}

/* Use this class if this intro text and the next section have the same background */
.intro {
  margin-bottom: -4rem;
}

.linknav {
  margin-bottom: 0.5rem;
  & a:not(:last-child) {
    margin-right: 1rem;
  }
}

